<template>
    <div class="apis-view">

        <!-- Introducción -->
        <div class="card_raw">
            <section class="introduction">
                <h1>Bienvenidos a la API pública de pickgeo.com</h1>
                <p>
                    En pickgeo.com somos especialistas en proveer datos geolocalizados a través de nuestras APIs
                    avanzadas.
                    Contamos con una amplia variedad de datos que abarcan desde información demográfica, datos de renta,
                    consumo, movilidad, entre muchos otros. Nuestra misión es ayudarte a acceder a la información más
                    relevante para tus necesidades, de forma rápida y precisa.
                </p>
            </section>
        </div>
        <!-- Sección 1: APIs de Pickgeo -->
        <div class="card">
            <section class="api-categories">
                <div class="header-container">
                    <h2>APIs de Pickgeo</h2>
                    <a href="https://api.pickgeo.com/api/geoapi/docs" target="_blank" style="margin-left: 0">
                        <NButton strong type="primary" tertiary>
                            Ir a los DOCS
                        </NButton>
                    </a>
                </div>
                <!-- <ul>
                    <li>Padrón completo de habitantes</li>
                    <li>Renta bruta y neta, por hogar y persona</li>
                    <li>Demográficos: edad, estado civil, nivel educativo, etc.</li>
                    <li>Consumo por categorías</li>
                    <li>Movilidad poblacional</li>
                    <li>Viviendas y plazas turísticas</li>
                    <li>Viviendas censadas</li>
                    <li>Inmuebles en catastro</li>
                    <li>Locales comerciales en alquiler y venta</li>
                    <li>Tránsito peatonal</li>
                    <li>Padrón (tendencia anual)</li>
                    <li>Catastro (tendencia anual)</li>
                    <li>Locales (tendencia mensual)</li>
                    <li>Movilidad (tendencia anual)</li>
                    <li>Marcadores comerciales (POIs)</li>
                </ul> -->
                <div class="container">
                    <div class="box" v-for="item in items" :key="item.title" @click="goToDocs">
                        <!-- <img :src="item.icon" alt="Icon" class="icon"> -->
                        <h2>{{ item.title }}</h2>
                        <p>{{ item.description }}</p>
                    </div>
                </div>
                <p>
                    Para saber más sobre las métricas concretas, visita los <strong>docs </strong>de esta api.
                </p>
            </section>
        </div>
        <!-- Sección 2: Niveles geográficos -->
        <div class="card">
            <section class="geographic-levels">
                <div class="header-container">
                    <h2>Niveles Geográficos</h2>
                    <a href="https://api.pickgeo.com/api/geoapi/docs" target="_blank" style="margin-left: 0">
                        <NButton strong type="primary" tertiary>
                            Ir a los DOCS
                        </NButton>
                    </a>
                </div>
                <p>
                    Puedes realizar consultas a nuestras APIs utilizando distintos niveles geográficos, como:
                </p>
                <ul>
                    <li>Polígono</li>
                    <li>Lat/Lon + radio</li>
                    <li>Lat/Lon + método desplazamiento + duración del trayecto</li>
                    <li>Diracción + radio</li>
                    <li>Dirección + método desplazamiento + duración del trayecto</li>
                    <li>Distrito censal</li>
                    <li>Código postal</li>
                    <li>Municipio</li>
                    <li>Provincia</li>
                </ul>
                <p>
                    Para saber más sobre las métricas concretas, visita los <strong>docs </strong>de esta api.
                </p>
            </section>
        </div>
    </div>
</template>

<script>
import {
    NButton,
} from 'naive-ui'
export default {
    name: 'APIsView',
    components: {
        NButton
    },
    methods: {
        navigate(path) {
            this.$router.push(path);
        }
    },
    data() {
        return {
            items: [
                { title: 'Padrón de habitantes', description: 'Población, origen, edad, género, etc.' },
                { title: 'Renta e ingresos', description: 'Renta bruta y neta desglosados por hogar y persona, origen de los ingresos, etc.' },
                { title: 'Datos demográficos', description: 'Edad, estado civil, nivel educativo, etc.' },
                { title: 'Consumo por categorías', description: 'Alimentación, textil, ocio, educación, etc.' },
                { title: 'Movilidad poblacional', description: 'Desplazamientos habituales de entrada y salida' },
                { title: 'Viviendas y plazas turísticas', description: 'Viviendas, plazas, plazas por vivienda, etc.' },
                { title: 'Censo de viviendas', description: 'Viviendas, superficie, antiguedad, habitaciones, etc.' },
                { title: 'Inmuebles en catastro', description: 'Inmuebles por tipo, superficie, año de construcción, etc.' },
                { title: 'Locales comerciales', description: 'Locales en alquiler y venta, superficies, precioes, etc.' },
                { title: 'Tránsito peatonal', description: 'Peatones en la vía por franjas horarias, días semanales, meses, etc.' },
                { title: 'Padrón (tendencia anual)', description: 'Datos de padrón anualizados' },
                { title: 'Catastro (tendencia anual)', description: 'Datos de catastro anualizados' },
                { title: 'Locales (tendencia mensual)', description: 'Datos de locales semanales' },
                { title: 'Movilidad (tendencia anual)', description: 'Datos de movilidad mensuales' },
                { title: 'Marcadores comerciales (POIs)', description: 'Puntos de interés por categoría comercial' },
                { title: 'Tránsito peatonal', description: 'Datos de tránsito de peatones a nivel tramo de vía' },
                { title: 'Gasto captable', description: 'Cálculo del gasto captable por sector comercial' },
                { title: 'Valoracion de zonas por IA', description: 'Valora y compara zonas por IA' }
            ]
        }
    },
    methods: {
        goToDocs() {
            window.open('https://api.pickgeo.com/api/geoapi/docs', '_blank');
        }
    }
}
</script>

<style scoped>
.apis-view {
    font-family: 'Ubuntu', sans-serif;
}

/* Estilos para las tarjetas */
.card {
    background-color: #ffffff;
    /* Fondo blanco */
    box-shadow: 0px 4px 50px 15px hsla(219, 52%, 26%, 0.1);
    /* Sombra más extendida */
    margin-bottom: 30px;
    /* Espacio entre tarjetas */
    padding: 20px;
    /* Espacio interno */
    border-radius: 8px;
    /* Bordes redondeados */
}

.card_raw {
    margin-bottom: 10px;
    /* Espacio entre tarjetas */
    padding: 20px;
    /* Espacio interno */
}

.header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* This will vertically align the title and the button */
}

.introduction,
.api-categories,
.geographic-levels {
    margin-bottom: 20px;
}

h1,
h2 {
    margin-top: 0;
    color: #333;
    font-weight: bold;
}

p {
    margin: 20px 0;
    font-size: 16px;
    line-height: 1.5;
}

ul {
    list-style-type: disc;
    padding-left: 40px;
}

li {
    margin: 5px 0;
}

@media (min-width: 768px) {
    .apis-view {
        padding: 20px;
        font-family: 'Ubuntu', sans-serif;
    }
}

.container {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
}

.box {
    border: 1px solid #ccc;
    padding: 20px;
    margin: 10px;
    width: 220px;
    text-align: center;
    transition: background-color 0.3s, border-color 0.3s;
    cursor: pointer;
}

.box:hover {
    background-color: #cad6ec;
    border-color: #2f5597;
    box-shadow: 0 0 15px rgba(91, 137, 216, 0.3);
    /* Halo effect */

}

.icon {
    width: 50px;
    height: 50px;
}
</style>